<template>
  <!-- Header -->
  <header
    :class="{
      'e-dialog__item--ingoing': !data.outgoing,
      'e-dialog__item--outgoing': data.outgoing,
      'e-dialog__item--read': data.read || data.outgoing,
      'e-dialog__item--open': itemOpen
    }"
    class="e-dialog__head"
  >
    <!-- Icon -->
    <div class="e-dialog__icon">
      <!-- Read icon -->
      <IconArrowIncomingFull v-if="!data.outgoing && !data.read" />

      <!-- Read icon -->
      <IconArrowIncomingLine v-else-if="!data.outgoing && data.read" />

      <!-- Answered icon -->
      <IconArrowOutgoingLine v-else />
    </div>
    <!-- /Icon -->

    <div class="e-dialog__author">
      <div class="e-dialog__name">
        {{ data.author }}
      </div>
      <div
        v-if="data.position && itemOpen"
        class="e-dialog__position"
      >
        {{ data.position }}
      </div>
    </div>

    <div class="e-dialog__preview">
      <div>{{ strippedBody }}</div>
    </div>

    <div class="e-dialog__meta">
      <time
        :datetime="data.timestamp"
        class="e-dialog__date"
      >
        {{ data.timestamp | simpledate }}
      </time>
    </div>
  </header>
</template>

<script>
import { mapActions } from 'vuex'

// Icons
import IconArrowIncomingLine from 'assets/icons/arrow-incoming-line.svg'
import IconArrowIncomingFull from 'assets/icons/arrow-incoming-full.svg'
import IconArrowOutgoingLine from 'assets/icons/arrow-outgoing-line.svg'

export default {
  name: 'DialogItemHead',
  components: {
    IconArrowIncomingFull,
    IconArrowIncomingLine,
    IconArrowOutgoingLine
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    itemOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    icons: {
      arrows: {
        ingoing: {
          full: require('assets/icons/arrow-incoming-full.svg'),
          line: require('assets/icons/arrow-incoming-line.svg')
        },
        outgoing: {
          line: require('assets/icons/arrow-outgoing-line.svg')
        }
      }
    }
  }),

  computed: {
    strippedBody() {
      const div = document.createElement('div')
      div.innerHTML = this.data.body
      return div.textContent || div.innerText || ''
    }
  },

  watch: {
    itemOpen: function() {
      this.onOpenMessage()
    }
  },
  mounted() {
    this.onOpenMessage()
  },
  methods: {
    ...mapActions(['markAsRead']),

    onOpenMessage() {
      if (this.itemOpen && !this.data.outgoing && this.data.read === false) {
        this.markAsRead({ id: this.data.id, rootId: this.data.rootId })
      }
    }
  }
}
</script>
