<template>
  <div class="e-dialog">
    <router-link
      v-if="$route.name === 'sentDialog'"
      :to="{ name: 'sent' }"
      class="e-dialog__back"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-else
      :to="{ name: 'dialogs' }"
      class="e-dialog__back"
    >
      <IconChevronLeft />
    </router-link>

    <VuePerfectScrollbar class="e-dialog__inner">
      <div class="e-dialog__scroll">
        <div
          v-if="currentDialog && currentDialog.absentDate"
          class="absence-info"
        >
          {{ currentDialog.author }} abwesend bis {{ absentDate }}
        </div>
        <div
          v-if="currentDialog && currentDialog.subject"
          class="e-dialog__subject"
        >
          <h3>{{ currentDialog.subject }}</h3>

          <div class="e-dialog__count">
            {{ currentDialog.threadCount }}
          </div>
        </div>

        <el-collapse v-model="activeNames">
          <!-- Collapse Item -->
          <el-collapse-item
            v-for="message in messages"
            :key="message.id"
            :name="message.id"
          >
            <template slot="title">
              <DialogItemHead
                :data="message"
                :item-open="activeNames.indexOf(message.id) > -1"
              />
            </template>

            <DialogItemBody
              :data="message"
              :item-open="activeNames.indexOf(message.id) > -1"
              :editor-enabled="isLatestCounselorMessage(message.id)"
              :root-id="rootId"
            />
          </el-collapse-item>
        </el-collapse>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import DialogItemHead from 'molecules/DialogItem/DialogItemHead'
import DialogItemBody from 'molecules/DialogItem/DialogItemBody'
import { format } from 'date-fns'

// Icons
import IconChevronLeft from 'assets/icons/chevron-left.svg'

export default {
  name: 'DialogWrapper',
  components: {
    DialogItemHead,
    DialogItemBody,
    IconChevronLeft,
    VuePerfectScrollbar
  },
  data: () => ({
    activeNames: []
  }),
  computed: {
    ...mapGetters(['currentDialog', 'userInfo']),

    computedMessages () {
      return this.currentDialog ? this.currentDialog.messages : []
    },

    absentDate () {
      return format(this.currentDialog.absentDate, 'dd.MM.yyyy')
    },

    messages () {
      if (this.computedMessages && this.computedMessages[0] && this.computedMessages[0].draft) {
        const messages = this.computedMessages.slice(1)
        return messages
      }
      return this.computedMessages
    },

    rootId () {
      return this.messages.length ? this.$route.params.dialogId : null
    },

    unreadMessages () {
      return this.messages.filter(
        (el) => !el.read && el.authorId !== this.userInfo.id && el.messageType === 'message'
      )
    }
  },
  watch: {
    messages: function (newVal, oldVal) {
      if (newVal.length > 0) {
        const replyMessage = newVal.filter((el) => el.reply === true)
        if (replyMessage.length > 0) {
          for (const item of replyMessage) {
            this.openMessage(item.id)
          }
        } else {
          if (this.$route.params.messageId) {
            this.openMessage(this.$route.params.messageId)
          } else {
            if (
              (newVal.length > 0 && oldVal.length > 0 && newVal[0].id !== oldVal[0].id) ||
              (newVal.length > 0 && oldVal.length === 0)
            ) {
              this.openMessage(newVal[0].id)
            }
          }
        }
      }
    },
    computedMessages () {
      this.setEditMessage()
    },
    unreadMessages: function (newVal, oldVal) {
      if (newVal.length === 0 && oldVal.length > 0) {
        this.storeDialogAsRead(this.currentDialog.id)
      }
    }
  },

  beforeDestroy () {
    this.resetEditMessages()
  },

  mounted () {
    if (this.messages.length > 0) {
      const replyMessage = this.messages.filter((el) => el.reply === true)
      if (replyMessage.length > 0) {
        for (const item of replyMessage) {
          this.openMessage(item.id)
        }
      } else {
        if (this.$route.params.messageId) {
          this.openMessage(this.$route.params.messageId)
        } else {
          this.openMessage(this.messages[0].id)
        }
      }
    }

    this.setEditMessage()
  },

  methods: {
    ...mapActions(['resetEditMessages', 'storeDialogAsRead']),

    openMessage (messageId) {
      Object.keys(this.messages).forEach((index) => {
        if (this.messages[index].id === messageId) this.activeNames = [messageId]
      })
    },

    setEditMessage () {
      const editMessage = this.computedMessages.slice(0, 1)[0]
      if (editMessage && editMessage.draft && this.computedMessages[1]) {
        this.resetEditMessages(editMessage)

        if (this.messages[0].outgoing !== false) {
          const counselorMessages = this.messages.filter((el) => el.outgoing === false)
          if (counselorMessages.length > 0) {
            this.openMessage(counselorMessages[0].id)
          }
        }
      }
    },

    isLatestCounselorMessage (id) {
      const counselorMessages = this.computedMessages.filter((el) => el.outgoing === false)
      return counselorMessages.length > 0 && counselorMessages[0].id === id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/dialog';
</style>
